@import url('https://fonts.googleapis.com/css?family=Oswald:400,700&display=swap');

@font-face {
    font-family: 'Tilda Sans';
    src: url('TildaSans-Regular.eot');
    src:
        url('TildaSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('TildaSans-Regular.woff2') format('woff2'),
        url('TildaSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('TildaSans-Bold.eot');
    src:
        url('TildaSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('TildaSans-Bold.woff2') format('woff2'),
        url('TildaSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

// @font-face {
//     font-family: 'Tilda Sans';
//     src: url('TildaSans-Light.eot');
//     src:
//         url('TildaSans-Light.eot?#iefix') format('embedded-opentype'),
//         url('TildaSans-Light.woff2') format('woff2'),
//         url('TildaSans-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
// }

@font-face {
    font-family: 'Tilda Sans';
    src: url('TildaSans-ExtraBold.eot');
    src:
        url('TildaSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('TildaSans-ExtraBold.woff2') format('woff2'),
        url('TildaSans-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

// @font-face {
// 	font-family: 'Tilda Sans';
// 	src: url('TildaSans-Medium.eot');
// 	src:
// 	     url('TildaSans-Medium.eot?#iefix') format('embedded-opentype'),
// 	     url('TildaSans-Medium.woff2') format('woff2'),
// 	     url('TildaSans-Medium.woff') format('woff');
// 	font-weight: normal;
// 	font-style: normal;
// }

// @font-face {
//     font-family: 'Tilda Sans';
//     src: url('TildaSans-Semibold.eot');
//     src:
//         url('TildaSans-Semibold.eot?#iefix') format('embedded-opentype'),
//         url('TildaSans-Semibold.woff2') format('woff2'),
//         url('TildaSans-Semibold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
// }