@import 'bootstrap';
@import './assets/fonts/fonts.scss';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.App {
  text-align: left;
  color: #212121;
  font-family: Tilda Sans;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}