$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.main-page {

  header {
    height: auto;
  }

  h1 {
    font-family: Tilda Sans;
    font-size: 165px;
    font-weight: 900;
    line-height: 198px;
    text-align: left;
    color: #FFFFFF;
  }

  h2 {
    @media (max-width: ($sm - 1)) {
      font-size: 28px;
      line-height: 47.6px;
    }

    font-family: Tilda Sans;
    font-size: 48px;
    font-weight: 900;
    line-height: 57.6px;
    text-align: left;
    color: #D00000;
    text-transform: uppercase;
    margin-bottom: 0;

    padding-top: 60px;
    padding-bottom: 60px;
  }

  h3 {
    font-family: Oswald;
    font-size: 24px;
    font-weight: 700;
    line-height: 35.57px;
    text-align: left;
  }

  h4 {
    font-family: Tilda Sans;
    font-size: 32px;
    font-weight: 900;
    line-height: 38.4px;
    text-align: left;
    color: #B6B6B6;
  }

  .main-header {
    background: url('../images/header_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    height: calc((800px / 1440px) * 100vw);

    h2 {
      padding-top: 30px;
      padding-left: 45px;
    }
  }

  .cms-logo {
    @media (max-width: ($md - 1)) {
      transform: scale(0.8);
      margin-left: -28px;
      top: calc((27px / 1440px) * 100vw);

    }

    @media (max-width: ($sm - 1)) {
      transform: scale(0.5);
      margin-left: -74px;
      // top: -14px;
      top: calc(((14px / 1440px) * 100vw) - 14px);

    }

    position: absolute;
    width: 282.75px;
    height: 81.75px;
    // top: 20px;
    top: calc((36px / 1440px) * 100vw);
  }

  .title-block {
    @media (min-width: ($xl - 1)) {
      transform: scale(1);
      top: 195px;
      bottom: 140px;
    }

    @media (max-width: ($xl - 1)) {
      transform: scale(0.8);
      top: 130px;
      margin-left: -82px;
    }

    @media (max-width: ($lg - 1)) {
      top: 145px;
    }

    @media (max-width: ($md - 1)) {
      transform: scale(0.6);
      top: 66px;
      margin-left: -150px;
    }

    @media (min-width: ($xxl - 1)) {
      // top: 195px;
      top: calc((200px / 1440px) * 100vw);
    }

    @media (max-width: ($sm - 1)) {
      transform: scale(0.4);
      top: -20px;
      margin-left: -224px;
    }

    position: absolute;
    top: 195px;

    .pre-title-block {
      @media (max-width: ($sm - 1)) {
        margin-bottom: 30px;
      }

      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      align-items: end;
    }

    img {
      height: 250px;
    }

    .pre-title {
      @media (max-width: ($sm - 1)) {
        font-size: 26px;
      }

      font-family: Tilda Sans;
      font-size: 26px;
      font-weight: 400;
      line-height: 40.6px;
      text-align: left;
      white-space: nowrap;
      margin-left: 24px;
    }

    .title-rect {
      width: 748px;
      height: 175px;
      gap: 7.5px;
      background: #D00000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .distance {
    @media (max-width: ($xl - 1)) {
      height: 60px;
    }

    @media (max-width: ($lg - 1)) {
      height: 160px;
    }

    height: 0px;
    background-color: #EFEEEC;
  }

  .description-block {
    background-color: #EFEEEC;
    width: 100vw;

    .col {
      padding-top: 0px;
    }

    .row {
      padding-top: 80px;
    }
  }

  .plan-block {
    @media (max-width: ($lg - 1)) {
      padding-top: 80px;
    }

    background: url('../images/border1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: calc((909px / 1440px) * 100vw);

    h3 {
      padding-top: 60px;
      padding-bottom: 40px;
    }

    .plan-desktop {
      @media (max-width: ($sm - 1)) {
        display: none;
      }

      display: block;

    }

    .plan-mobile {
      @media (max-width: ($sm - 1)) {
        display: block;
      }

      display: none;
    }
  }

  .video-block {
    background: url('../images/video_bg.png');
    // background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: calc((771px / 1440px) * 100vw);

    margin-top: 60px;
    // padding-top: 60px;
    padding-bottom: 60px;

    h2 {
      color: #ffffff;
      padding-bottom: 20px;
    }

    h4 {
      // padding-top: 20px;
      padding-bottom: 60px;
    }
  }

  .contest-block {
    @media (max-width: ($xxl - 1)) {
      background: url('../images/border2.svg');
      background-size: cover;
      background-repeat: no-repeat;
      min-height: calc((909px / 1440px) * 100vw);
    }

    margin-top: 80px;
    margin-bottom: -1px;

    width: 100vw;

    #background {
      @media (max-width: ($xxl - 1)) {
        display: none;
      }

      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;

      .stretch {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      padding-top: 20px;
      // margin-top: 20px;
      // margin-bottom: 60px;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .row {
      padding-bottom: 0;
    }
  }

  .essays-block {
    @media (max-width: ($xxl - 1)) {
      padding-top: 20px;
    }

    padding-top: 80px;

    background-color: #EFEEEC;
    width: 100vw;

    .react-3d-carousel {
      @media (max-width: ($sm - 1)) {
        min-height: 408px;
      }

      min-height: 858px;

    }

    .react-3d-carousel .slider-container .slider-content {
      width: 100%;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
      opacity: 0.6;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
      opacity: 0.6;
    }

    .essay {
      @media (max-width: ($md - 1)) {
        min-height: 744px;
        padding: 40px 30px 30px;
        background-size: contain;

        font-size: 16px;
        line-height: 16px;
      }

      @media (max-width: ($sm - 1)) {
        max-width: 275px;
        min-height: 404px;
        padding: 40px 30px 30px;
        background-size: contain;

        font-size: 12px;
        line-height: 12px;
      }

      p {
        @media (max-width: ($md - 1)) {
          font-size: 18px;
          line-height: 24px;
        }

        @media (max-width: ($sm - 1)) {
          font-size: 12px;
          line-height: 12px;
        }
      }

      background: url('../images/letter_bg.svg');
      background-size: cover;
      background-repeat: no-repeat;

      max-width: 580px;
      min-height: 858px;
      padding: 80px 60px 60px;
      position: relative;

      .cite-bottom {
        @media (max-width: ($sm - 1)) {
          font-size: 12px;
          line-height: 12px;
          bottom: 5rem;
          right: 4rem;
        }

        position: absolute;
        width: 60%;
        height: 61px;
        bottom: 10rem;
        right: 82px;
        text-align: right;
      }

      .stamp {
        @media (max-width: ($sm - 1)) {
          width: 91px;
          height: 19px;  
          bottom: calc(100% + 11px);
        }

        background: url('../images/stamp.svg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 273px;
        height: 58px;

        bottom: calc(100% + 37px);
        position: absolute;
        right: 0;
      }
    }

    .slick-slide {
      margin-left: -100px;
      margin-right: -100px;
      max-width: 480px;
      min-width: 480px;
      max-height: 710px;
      min-height: 710px;
      opacity: 0.6;
      z-index: 1;
      position: relative;

      .essay {
        max-width: 480px;
        min-width: 480px;
        max-height: 710px;
        min-height: 710px;
      }
    }

    .slick-center {
      max-width: 580px;
      min-width: 580px;
      max-height: 858px;
      min-height: 858px;
      opacity: 1;
      z-index: 100;

      .essay {
        max-width: 580px;
        min-width: 580px;
        max-height: 858px;
        min-height: 858px;
      }
    }
  }

  .history-block {
    @media (max-width: ($xxl - 1)) {
      background: url('../images/border3.svg');
      background-size: contain;
      background-repeat: no-repeat;
      min-height: calc((909px / 1440px) * 100vw);
      margin-bottom: -1px;
    }

    // margin-bottom: 160px;
    width: 100vw;


    #background {
      @media (max-width: ($xxl - 1)) {
        display: none;
      }

      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;

      .stretch {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      padding-top: 80px;
      padding-bottom: 40px;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .row {
      padding-bottom: 0;
    }
  }

  .form-block {

    iframe {
      max-width: 100%;
    }

    .md-a {
      @media (max-width: ($lg - 1)) {
        flex: 0 0 auto;
        width: auto;
      }
    }
  }

  footer {
    @media (max-width: ($xl - 1)) {
      height: 120px;
    }

    @media (max-width: ($lg - 1)) {
      font-size: 12px;
    }

    width: 100vw;
    height: 100px;
    background-color: #666462;
    margin-top: 160px;

    font-family: Tilda Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    color: #FFFFFF;

    .footer-container {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%
    }

    .footer-info {
      @media (max-width: ($xl - 1)) {
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%
    }

    a {
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}